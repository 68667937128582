<template>
  <admin-layout isLayout="admin">
    <div class="container m-2 m-sm-5 mw-100 w-auto">
      <div class="mb-4 w-100 d-flex justify-content-end">
        <b-button
          @click="downloadExcel"
          variant="success"
          :disabled="isDownloadExcel"
        >
          <span class="mr-2">Excel İndir</span>
          <b-icon icon="download"></b-icon>
        </b-button>
      </div>
      <SuggestionHeader
        @search-filter="suggestionSearchFilter"
        @clear-search-filter="clearSearchFilter"
        :totalIdea="evaluationTotalRecords"
        suggestionTypeId="1"
        @order-data="orderData"
      />

      <div class="row">
        <AdminTable
          :columns="columns"
          :rows="getEvaluationData"
          @table-detail="cardDetail($event)"
        />

        <div
          class="row mt-4 d-flex align-items-center justify-content-center w-100"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="evaluationTotalRecords"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>

      <div class="row text-right d-flex align-items-center justify-content-end">
        <span class="text-black-50 font-weight-bold"
          >Toplam:{{ evaluationTotalRecords }} Adet</span
        >
      </div>
      <transition name="fade">
        <EvaluationModal
          v-if="isShareEvaluation"
          :detailData="evaluationItem"
          :searchFilterData="filterData"
          @close="isShareEvaluation = $event"
        />
      </transition>
    </div>
  </admin-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import { sortDate, lowerCaseKeys} from "../../helpers";
import { suggestionService, upload } from "../../services";

import AdminLayout from "../../layouts/AdminLayout.vue";
import SuggestionHeader from "../../components/Admin/SuggestionHeader";
import AdminTable from "../../components/Admin/AdminTable.vue";
import EvaluationModal from "../../components/Admin/EvaluationModal.vue";
export default {
  components: { AdminLayout, SuggestionHeader, AdminTable, EvaluationModal },
  name: "Evaluation",
  data() {
    return {
      isShareEvaluation: false,
      evaluationItem: null,
      currentPage: 1,
      perPage: 15,
      columns: [
        {
          label: "Fikir No.",
          field: "SuggestionNumber",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir Sahibi",
          field: "CreatedCustomerName",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir Tarihi",
          field: "SuggestionDate",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Sorun/Fırsat",
          field: "Problem",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "FİKİR",
          field: "SuggestionDetail",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Değer Akımı",
          field: "ValueCurrentName",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Statü",
          field: "SuggestionStatusName",
          sortable: false,
          thClass: "vgt-center-align",
        },
        {
          label: "",
          field: "action",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
      ],
      filterData: null,
      isDownloadExcel: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      evaluationTotalRecords: "suggestion/evaluationTotalRecords",
    }),
    getEvaluationData() {
      return this.$store.getters["suggestion/getEvluationData"];
    },
    gettersStatusByRoles() {
      return this.$store.getters["suggestion/statusByRoles"];
    },
    getEvaluationIdData() {
      return this.$store.getters["suggestionNewests/newestsIdData"];
    },
  },
  watch: {
    currentPage() {
      this.searchFilter(this.filterData);
    },
    gettersStatusByRoles() {
      if (this.getEvaluationData.length == 0 && this.gettersStatusByRoles)
        this.searchFilter(this.filterData);
    },
    getEvaluationIdData: {
      handler(val) {
        if (val.length > 0) {
          this.routeFilterData();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    await this.searchFilter();
    const route = lowerCaseKeys(this.$route.query);
    if (route.id) {
      await this.$store.dispatch("suggestionNewests/setIdData", route.id);
    }
  },
  methods: {
    clearSearchFilter(event) {
      this.currentPage = 1;
      this.searchFilter(event);
    },
    suggestionSearchFilter(event){
      this.currentPage = 1;
      this.searchFilter(event); 
    },
    async searchFilter(event) {
      let tmpData = null;
      tmpData = {
        ...event,
        ...{
          pageSize: this.perPage,
          pageIndex: parseInt(this.currentPage - 1),
          loginUserId: this.user.Data.UserId,
          isAdminPage: true,
        },
      };
      this.filterData = tmpData;
      this.$store.dispatch("suggestion/deleteEvaluationData");
      this.$store.dispatch("suggestion/setEvaluationData", tmpData);
    },
    orderData(event) {
      sortDate(this.getEvaluationData, event);
    },
    async cardDetail(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.evaluationItem = item;
      this.evaluationItem.DownloadFile = [];

      if (
        this.evaluationItem.FileIds &&
        this.evaluationItem.FileIds.length > 0
      ) {
        for await (let element of this.evaluationItem.FileIds) {
          const res = await upload.fetchDownloadFile(element);
          this.evaluationItem.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isShareEvaluation = true;
    },
    async downloadExcel() {
      const tmpData = {
        pageSize: this.evaluationTotalRecords,
        pageIndex: 0,
        CompanyId:
          this.filterData == undefined
            ? this.user.Data.CompanyId
            : this.filterData?.companyId == ""
            ? ""
            : this.filterData?.companyId,
        ValueCurrentId: this.filterData?.valueCurrentId
          ? this.filterData?.valueCurrentId
          : null,
        SuggestionStatuses: this.filterData?.suggestionStatuses
          ? this.filterData?.suggestionStatuses
          : [],
        CreatedUserId: this.filterData?.createdUserId
          ? this.filterData?.createdUserId
          : null,
        SearchKeyword: this.filterData?.searchKeyword
          ? this.filterData?.searchKeyword
          : null,
        StartDate: this.filterData?.startDate
          ? this.filterData?.startDate
          : null,
        SuggestionNumber: this.filterData?.suggestionNumber
          ? this.filterData?.suggestionNumber
          : null,          
        EndDate: this.filterData?.endDate ? this.filterData?.endDate : null,
        IsAdminPage: true,
        LoginUserId: this.user.Data.UserId,
        FilterReset: this.filterData?.filterReset,
      };
      this.isDownloadExcel = true;
      const res = await suggestionService.fetchSuggestionDownloadExcel(tmpData);
      const file = `data:image/png;base64,${res.Data.DownloadBinary}`;
      const fileName = `${res.Data.Filename}.${res.Data.Extension}`;
      saveAs(file, fileName);
      this.isDownloadExcel = false;
    },
    routeFilterData() {
      const filteredData = this.getEvaluationIdData[0];
      const route = lowerCaseKeys(this.$route.query);
      filteredData && route.id ? this.cardDetail(filteredData) : "";
    },
  },
};
</script>

<style></style>
