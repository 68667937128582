<template>
  <div>
    <div id="modalDetail">
      <div
        id="modal-idea-detail"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        style="display: block; background-color: rgba(0, 0, 0, 0.5)"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content" ref="modalContent">
            <div class="modal-header bg-blue-3 rounded-0">
              <h5 class="modal-title text-center text-white mx-auto">
                FİKİR DETAYI
              </h5>
              <button type="button" class="close" @click="close">
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div class="container mb-3 mw-100">
              <div class="printToPdf">
                <div class="row pl-3 pl-sm-5 modal-bar">Fikir Bilgileri</div>
                <dl class="row px-3 px-sm-5 pt-3 mb-0">
                  <dt>Fikir No</dt>
                  <dd>{{ detailData.SuggestionNumber }}</dd>

                  <dt>Şirket</dt>
                  <dd>{{ detailData.CompanyName }}</dd>

                  <dt>Fikri Yapan</dt>
                  <dd class="text-uppercase">
                    {{ detailData.CreatedCustomerName }} (
                    {{ detailData.CreatedCustomerCompanyName }} )
                  </dd>

                  <dt>Bölge</dt>
                  <dd>{{ detailData.OfficeLocation }}</dd>

                  <dt>Fikir Tarihi</dt>
                  <dd>
                    {{ detailData.SuggestionDate | newDateFormatterFilter }}
                  </dd>

                  <dt>Bölüm</dt>
                  <dd>{{ detailData.Department }}</dd>

                  <dt>Statü</dt>
                  <dd class="single">
                    {{ detailData.SuggestionStatusName }}
                  </dd>

                  <dt
                    v-if="
                      detailData && detailData.SuggestionStatusId == 10 //iptal edilmiş fikir
                    "
                  >
                    İptal Nedeni
                  </dt>
                  <dd
                    class="single"
                    v-if="
                      detailData && detailData.SuggestionStatusId == 10 //iptal edilmiş fikir
                    "
                  >
                    {{ detailData.ProjectCancelledReason }}
                  </dd>

                  <dt
                    v-if="
                      detailData && detailData.SuggestionStatusId == 8 //hayata geçmiş fikir
                    "
                  >
                    Hayata Geçiş Tarihi
                  </dt>
                  <dd
                    class="single"
                    v-if="
                      detailData && detailData.SuggestionStatusId == 8 //hayata geçmiş fikir
                    "
                  >
                    {{ detailData.ProjectEndDate | newDateFormatterFilter }}
                  </dd>

                  <dt>Değer Akımı</dt>
                  <dd class="single">{{ detailData.ValueCurrentName }}</dd>
                </dl>

                <dl class="row px-3 px-sm-5 pt-3 mb-0">
                  <dt>Sorun</dt>
                  <dd class="single">{{ detailData.Problem }}</dd>
                  <dt>Fikir</dt>
                  <dd class="single">{{ detailData.SuggestionDetail }}</dd>
                  <dt>Beklenen Fayda</dt>
                  <dd class="single">{{ detailData.ExpectedBenefit }}</dd>
                  <dt>Dokümanlar</dt>
                  <dd class="single" v-if="detailData.DownloadFile.length > 0">
                    <div v-for="file in detailData.DownloadFile" :key="file.Id">
                      <a
                        :href="
                          'data:' +
                          file.fileType +
                          ';base64,' +
                          file.DownloadBinary
                        "
                        :download="file.Filename + '.' + file.Extension"
                        class="innovation-pdf mr-4"
                        title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                        alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                        target="_blank"
                      >
                        {{ file.Filename }}.{{ file.Extension }}
                        <span class="fas fa-file-download"></span>
                      </a>
                    </div>
                  </dd>
                </dl>

                <div class="row pl-3 pl-sm-5 mb-2 modal-bar">
                  Etkileşim Bilgileri
                </div>

                <div class="row px-3 px-sm-5">
                  <a
                    href="#"
                    class="article-comment-show icon-down"
                    @click="isComment = !isComment"
                    >{{ detailData.CommentCount }} Yorum</a
                  >
                  <span class="article-comment-like"
                    ><i class="fas fa-thumbs-up"></i
                    >{{ detailData.LikeCount }}</span
                  >
                  <article
                    v-if="isComment"
                    class="article-comment-container"
                    type="Suggestion"
                    objectid="718d7c1f-7dec-eb11-bacb-000d3ade7034"
                  >
                    <div id="suggestiondetailcomments">
                      <div
                        id="commentdiv"
                        v-for="comment in detailData.Comments"
                        :key="comment.Id"
                      >
                        <div
                          class="article-comment"
                          id="article-comment-id"
                          style="position: static"
                        >
                          <div class="article-comment-body">
                            <img
                              v-if="comment.UserProfileImage"
                              class="article-user-img fallback-image"
                              :src="`data:image/png;base64, ${comment.UserProfileImage}`"
                              :alt="comment.UserName | getInitialsFilter"
                            />
                            <i
                              v-else
                              class="comment-user-img fallback-image"
                              :alt="comment.UserName | getInitialsFilter"
                              >{{ comment.UserName | getInitialsFilter }}</i
                            >
                            <span
                              >{{ comment.UserName }} (
                              {{ comment.CreatedCustomerCompanyName }} )</span
                            ><span
                              class="icon-clock"
                              :title="comment.CreatedOnUtc"
                              >{{ comment.CreatedOnUtc | dateDiffFilter }}</span
                            >
                            <p
                              style="
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                              "
                            >
                              {{ comment.CommentText }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                <div>
                  <div class="row pl-5 mb-2 modal-bar">
                    Fikir Dağıtıcısı Bilgileri
                  </div>
                  <dl class="row px-5 pt-3 mb-0">
                    <dt>Fikir Dağıtıcısı</dt>
                    <dd>
                      <span v-if="detailData.SuggestionExecutiveName">
                        {{ detailData.SuggestionExecutiveName }} (
                        {{ detailData.SuggestionExecutiveCompanyName }} )
                      </span>
                    </dd>

                    <dt>İşlem Tarihi</dt>
                    <dd>
                      {{
                        detailData.SuggestionExecutiveProcessDate
                          | newDateFormatterFilter
                      }}
                    </dd>

                    <dt>Statü</dt>
                    <dd>
                      {{ detailData.SuggestionStatusName }}
                    </dd>

                    <dt>Fikir Değerlendirme Açıklaması</dt>
                    <dd>{{ detailData.SuggestionExecutiveNote }}</dd>

                    <dt
                      v-if="
                        detailData.ReasonForReturn &&
                        detailData.SuggestionStatusId == '1'
                      "
                    >
                      Geri Gönderme Nedeni
                    </dt>
                    <dd
                      v-if="
                        detailData.ReasonForReturn &&
                        detailData.SuggestionStatusId == '1'
                      "
                    >
                      {{ detailData.ReasonForReturn }}
                    </dd>
                  </dl>
                </div>

                <div
                  id="idea-evaluation-information"
                  v-if="
                    detailData.SuggestionStatusId == '2' ||
                    detailData.SuggestionStatusId == '3' ||
                    detailData.SuggestionStatusId == '6' ||
                    detailData.SuggestionStatusId == '8' ||
                    detailData.SuggestionStatusId == '4' ||
                    detailData.SuggestionStatusId == '9'
                  "
                >
                  <div class="row pl-5 mb-2 modal-bar">
                    Fikir Değerlendirme Bilgileri
                  </div>
                  <dl class="row px-5 pt-3 mb-0">
                    <dt>Fikir Değerlendirici</dt>
                    <dd>
                      {{ detailData.SuggestionEvaluatorName }} (
                      {{ detailData.SuggestionEvaluatorCompanyName }} )
                    </dd>

                    <dt>İşlem Tarihi</dt>
                    <dd>
                      {{
                        detailData.SuggestionEvaluatorProcessDate
                          | newDateFormatterFilter
                      }}
                    </dd>

                    <dt>Fikir Değerlendirme Açıklaması</dt>
                    <dd class="single">
                      {{ detailData.SuggestionEvaluatorNote }}
                    </dd>

                    <dt
                      v-if="
                        detailData.ReasonForReturn &&
                        detailData.SuggestionStatusId == '2'
                      "
                    >
                      Geri Gönderme Nedeni
                    </dt>
                    <dd
                      v-if="
                        detailData.ReasonForReturn &&
                        detailData.SuggestionStatusId == '2'
                      "
                    >
                      {{ detailData.ReasonForReturn }}
                    </dd>
                  </dl>
                </div>

                <div
                  id="idea-maker-information"
                  v-if="
                    detailData.SuggestionStatusId == '3' ||
                    detailData.SuggestionStatusId == '6' ||
                    detailData.SuggestionStatusId == '8' ||
                    detailData.SuggestionStatusId == '4' ||
                    detailData.SuggestionStatusId == '9'
                  "
                >
                  <!-- <div class="row pl-5 mb-2 modal-bar">
                  Fikir Karar Verici Bilgileri
                </div>
                <dl class="row px-5 pt-3 mb-0">
                  <dt>Fikir Karar Verici</dt>
                  <dd>
                    {{ detailData.SuggestionDecisionMakerName }} (
                    {{ detailData.SuggestionDecisionMakerCompanyName }} )
                  </dd>

                  <dt>İşlem Tarihi</dt>
                  <dd>
                    {{
                      detailData.SuggestionDeciderProcessDate
                        | newDateFormatterFilter
                    }}
                  </dd>

                  <dt>Statü</dt>
                  <dd>
                    {{ detailData.SuggestionStatusId | suggestionStatusFilter }}
                  </dd>
                  <dt>Fikir Değerlendirme Açıklaması</dt>
                  <dd>{{ detailData.SuggestionDeciderNote }}</dd>

                  <dt v-if="detailData.SuggestionStatusId == '4'">
                    Fikir Ret Açıklaması
                  </dt>
                  <dd v-if="detailData.SuggestionStatusId == '4'">
                    {{ detailData.CancelDescription }}
                  </dd>

                  <dt v-if="detailData.SuggestionStatusId == '4'">
                    Fikir Ret Nedeni
                  </dt>
                  <dd v-if="detailData.SuggestionStatusId == '4'">
                    {{ detailData.CancelReasonId | cancelReasonFilter }}
                  </dd>
                </dl> -->
                </div>
                <div>
                  <div class="row pl-3 pl-sm-5 mb-2 modal-bar">
                    Değerlendirme İşlemleri
                  </div>
                  <Distributor
                    v-if="detailData.SuggestionStatusId == '1'"
                    @send-update="sendUpdateDistributor"
                    @decline-submit="declineSubmit"
                    @on-remove-value="onRemoveValueDistributor"
                    @on-clear-value="onClearValueDistributor"
                    :detailData="detailData"
                  />
                  <Evaluation
                    v-if="detailData.SuggestionStatusId == '2'"
                    @send-update="sendUpdateEvaluation"
                    @send-reason-return="sendReasonReturn"
                    @decline-submit="declineSubmitEvaluation"
                    @on-clear-return-value="onClearReturnValue"
                    @on-remove-value="onRemoveValue"
                    :detailData="detailData"
                    :typeId="1"
                  />
                  <Decider
                    v-if="detailData.SuggestionStatusId == '3'"
                    @decline-submit="declineSubmitDecider"
                    @send-update="sendUpdateDecider"
                    @send-reason-return="sendReasonReturnDecider"
                    @on-remove-value="onRemoveValueDecider"
                    @on-clear-return-value="onClearReturnValueDecider"
                    @on-clear-value="onClearValueDecider"
                    :detailData="detailData"
                  />
                  <Approved
                    v-if="
                      detailData.SuggestionStatusId == '6' ||
                      detailData.SuggestionStatusId == '9'
                    "
                    @save-project-progress="saveProjectProgress"
                    @suggestion-result-submit="suggestionResultSubmit"
                    :detailData="detailData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isExitmodal"
        id="Warning"
        class="modal fade show"
        style="height: 200px; display: block"
        role="dialog"
        data-backdrop="false"
      >
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-body">
              <p>Fikri paylaşmadan çıkmak istediğinize emin misiniz?</p>
              <button
                type="button"
                class="btn btn-danger confirmclosed"
                style="margin-left: 27%"
                @click.stop="exitModal"
              >
                Çık
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2"
                data-dismiss="modal"
                @click.stop="isExitmodal = false"
              >
                Devam Et
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  suggestionStatus,
  dateFormatter,
  getInitials,
  newDateFormatter,
  dateDiff,
  cancelReason,
  currentDateTime,
} from "../../helpers";
import { suggestionService } from "../../services";
import Distributor from "./Modal/Distributor.vue";
import Evaluation from "./Modal/Evaluation.vue";
import Decider from "./Modal/Decider.vue";
import Approved from "./Modal/Approved.vue";
export default {
  name: "EvaluationModal",
  data() {
    return {
      isComment: false,
      isExitmodal: false,
    };
  },
  components: {
    Distributor,
    Evaluation,
    Decider,
    Approved,
  },
  props: {
    detailData: {
      type: Object,
    },
    searchFilterData: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    dateFormatterFilter(value) {
      return dateFormatter(value);
    },
    dateDiffFilter(value) {
      return dateDiff(value);
    },
    newDateFormatterFilter(value) {
      return newDateFormatter(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
    cancelReasonFilter(value) {
      return cancelReason(value);
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    gettersStatusByRoles() {
      return this.$store.getters["suggestion/statusByRoles"];
    },
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
  },
  methods: {
    getCurrentDateTime() {
      return currentDateTime();
    },
    onClearValueDecider() {
      this.close();
    },
    onClearReturnValueDecider() {
      this.close();
    },
    onRemoveValueDecider() {
      this.close();
    },
    onClearValueDistributor() {
      this.close();
    },
    onRemoveValueDistributor() {
      this.close();
    },
    onClearReturnValue() {
      this.close();
    },
    onRemoveValue() {
      this.close();
    },
    exitModal() {
      this.isExitmodal = false;
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    close(isSaveEditBtn = false) {
      if (!isSaveEditBtn) {
        this.isExitmodal = true;
      } else {
        this.$emit("close", false);
        const $body = document.querySelector("body");
        $body.style.removeProperty("overflow");
      }
    },
    clickOutSide(e) {
      let el = this.$refs.modalContent;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.close({ isSaveEditBtn: false });
      }
    },
    async updateModalAPI(tmpData) {
      try {
        await suggestionService.fetchUpdateSuggestion(tmpData);
        this.$toast.success("Başarılı bir şekilde güncellendi.", {
          position: "top",
        });
        const data = this.searchFilterData;
        tmpData?.companyId ? (data.companyId = tmpData.companyId) : "";
        this.$store.dispatch("suggestion/deleteEvaluationData");
        this.$store.dispatch("suggestion/setEvaluationData", data);
        this.close({ isSaveEditBtn: true });
      } catch (error) {
        this.$toast.error("Güncelleştirme başarısız.", {
          position: "top",
        });
      }
    },
    async sendUpdateDistributor(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }

      tmpData.SuggestionSubjectId = event.suggestionSubject;
      tmpData.SuggestionEvaluatorId = event.suggestionEvaluator;
      tmpData.SuggestionExecutiveNote = event.suggestionExecutiveNote;
      tmpData.ValueCurrentId = event.valueCurrentId;
      tmpData.SuggestionStatusId = 2;
      tmpData.SuggestionExecutiveId = this.user.Data.UserId;
      tmpData.SuggestionExecutiveProcessDate = this.getCurrentDateTime();

      await this.updateModalAPI(tmpData);
    },
    async sendUpdateEvaluation(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }

      tmpData.SuggestionSubjectId = event.suggestionSubject;
      //tmpData.SuggestionExecutiveNote = event.suggestionExecutiveNote;

      tmpData.ValueCurrentId = event.valueCurrentId;

      //3 -> 6
      tmpData.SuggestionStatusId = 6;

      tmpData.SuggestionEvaluatorId = this.user.Data.UserId;
      tmpData.SuggestionEvaluatorProcessDate = this.getCurrentDateTime();

      // tmpData.AboutExpectedBenefit = event.aboutExpectedBenefit;
      // tmpData.ApplicationCost = event.applicationCost;
      // tmpData.SuggestedApplicationId = event.suggestedApplication;
      // tmpData.SuggestionDecisionMakerId = event.suggestionEvaluator;
      tmpData.AboutProblem = event.aboutProblem;
      tmpData.AboutSuggestion = event.aboutSuggestion;
      tmpData.SuggestedTeam = event.suggestedTeam;
      tmpData.WorkHour = event.workHour;

      tmpData.SuggestionEvaluatorNote = event.suggestionEvaluatorNote;

      await this.updateModalAPI(tmpData);
    },
    async sendReasonReturn(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }
      tmpData.SuggestionStatusId = 1;
      tmpData.SuggestionEvaluatorId = this.user.Data.UserId;
      tmpData.SuggestionEvaluatorProcessDate = this.getCurrentDateTime();
      tmpData.ReasonForReturn = event.reasonForReturn;
      await this.updateModalAPI(tmpData);
    },
    async declineSubmit(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }
      tmpData.SuggestionStatusId = 4;
      tmpData.CancelReasonId = event.cancelReason;
      tmpData.CancelDescription = event.cancelDescription;
      tmpData.SuggestionExecutiveId = this.user.Data.UserId;
      tmpData.SuggestionExecutiveProcessDate = this.getCurrentDateTime();
      await this.updateModalAPI(tmpData);
    },
    async declineSubmitEvaluation(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }
      tmpData.SuggestionStatusId = 4;
      tmpData.CancelReasonId = event.cancelReason;
      tmpData.CancelDescription = event.cancelDescription;
      await this.updateModalAPI(tmpData);
    },
    async declineSubmitDecider(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }
      tmpData.SuggestionStatusId = 4;
      tmpData.CancelReasonId = event.cancelReason;
      tmpData.CancelDescription = event.cancelDescription;
      tmpData.SuggestionDecisionMakerId = this.user.Data.UserId;
      tmpData.SuggestionDeciderProcessDate = this.getCurrentDateTime();
      await this.updateModalAPI(tmpData);
    },
    async sendReasonReturnDecider(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }
      tmpData.SuggestionStatusId = 2;
      tmpData.SuggestionDecisionMakerId = this.user.Data.UserId;
      tmpData.SuggestionDeciderProcessDate = this.getCurrentDateTime();
      tmpData.ReasonForReturn = event.reasonForReturn;
      await this.updateModalAPI(tmpData);
    },
    async sendUpdateDecider(event) {
      let tmpData = {};

      for (let key in this.detailData) {
        tmpData[key] = this.detailData[key];
      }

      tmpData.SuggestionSubjectId = event.suggestionSubject;
      tmpData.SuggestionDeciderNote = event.suggestionDeciderNote;
      tmpData.ValueCurrentId = event.valueCurrentId;
      tmpData.SuggestionStatusId = 6;
      tmpData.SuggestionDecisionMakerId = this.user.Data.UserId;
      tmpData.SuggestionDeciderProcessDate = this.getCurrentDateTime();

      tmpData.TeamLeaderId = event.teamLeaderId;

      await this.updateModalAPI(tmpData);
    },
    async saveProjectProgress(event) {
      const tmpData = this.detailData;
      tmpData.ProjectProgress = event;
      await this.updateModalAPI(tmpData);
    },
    async suggestionResultSubmit(event) {
      const tmpData = this.detailData;
      tmpData.SuggestionStatusId = event.id;
      tmpData.ProjectEndDate = event.projectEndDate;
      tmpData.ProjectCancelledReason = event.projectCancelledReason;
      tmpData.ProjectCancelledDate = event.projectCancelledDate;
      tmpData.ProjectPendingReason = event.projectPendingReason;
      tmpData.PendingProcessDate = event.pendingProcessDate;
      tmpData.AboutExpectedBenefit = event.aboutExpectedBenefit;
      tmpData.ApplicationCost = event.applicationCost;
      tmpData.EvaluatorFileIds = event.evaluatorFileIds;
      await this.updateModalAPI(tmpData);
    },
  },
};
</script>

<style></style>
